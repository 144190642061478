import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import {
  Box,
  ButtonContained,
  ButtonOutlinedLink,
  ButtonTextLink,
  Card,
  LayoutButton,
  LayoutButtonWithTypography,
  LayoutVertical,
  Typography,
} from "@/components";
import DOMPurify from "dompurify";

type ButtonLink = {
  label: React.ReactNode;
  to: string;
};

type FooterButtonLink = ButtonLink & {
  title: string;
};

export type Props = {
  title: React.ReactNode;
  message: React.ReactNode;
  showButton?: boolean;
  primaryButton?: ButtonLink;
  footerButtons?: FooterButtonLink[];
};

export const LayoutGeneralError: React.FC<Props> = ({ title, message, primaryButton, footerButtons }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sanitizedMessage = DOMPurify.sanitize(message as string);

  return (
    <LayoutVertical>
      <Card>
        <Typography variant="h2" gutterBottom={!!message}>
          {title}
        </Typography>

        <LayoutVertical>
          <Typography style={{ whiteSpace: "pre-line" }}>{parse(sanitizedMessage)}</Typography>

          {primaryButton && (
            <LayoutButton>
              <ButtonContained
                onClick={async () => navigate(primaryButton.to, { state: location.state })}
                role="link"
                size="large"
              >
                {primaryButton.label}
              </ButtonContained>
            </LayoutButton>
          )}
        </LayoutVertical>
      </Card>
      {footerButtons?.map((button, index) => (
        <React.Fragment key={`${button.to}${index}`}>
          {button.to.includes("_portal/") ? (
            <LayoutButtonWithTypography>
              <Typography>{button.title}</Typography>
              <ButtonOutlinedLink to={button.to}>{button.label}</ButtonOutlinedLink>
            </LayoutButtonWithTypography>
          ) : (
            <Box textAlign="center">
              <ButtonTextLink to={button.to} target="_blank">
                {button.label}
              </ButtonTextLink>
            </Box>
          )}
        </React.Fragment>
      ))}
    </LayoutVertical>
  );
};
